.bccampus-page-section {
  position  : relative;
  padding   : var(--page-section-padding-v) var(--page-section-padding-h);
  width     : 100%;
  transition: height 1s;
}

.bccampus-page-section-anchor {
  position: relative;
  top     : calc(var(--page-section-padding-v) * -1 - 3em);
}

.bccampus-page-section.padding-vertical {
  padding: var(--page-section-padding-v) 0em;
}

.bccampus-page-section.padding-horizontal {
  padding: 0em var(--page-section-padding-h);
}

.bccampus-page-section.padding-none {
  padding: 0em !important;
}

.bccampus-page-section.expandable {
  height        : 6em;
  overflow      : hidden;
  padding-bottom: 0em !important;
}

.bccampus-page-section-actions {
  width         : 100%;
  text-align    : center;
  padding-top   : 2em;
  padding-bottom: 2em;
  transition    : padding 500ms;
}

.bccampus-page-section.expanded+.bccampus-page-section-footer .bccampus-page-section-actions {
  padding-top: 1em;
}

.bccampus-page-section.expanded+.bccampus-page-section-footer .bccampus-page-section-actions::before {
  opacity: 0;
  height : 0;
}

.bccampus-page-section+.bccampus-page-section-footer .bccampus-page-section-actions::before {
  height          : 5em;
  background-image: linear-gradient(180deg, rgba(var(--background-color-rgb), 0), var(--background-color));
  content         : "";
  width           : 100%;
  position        : absolute;
  bottom          : 5em;
  left            : 0;
  opacity         : 1;
  transition      : opacity 500ms, height 500ms;
}

.bccampus-page-section.background-alt+.bccampus-page-section-footer {
  background-color: var(--background-color-alt);
}

.bccampus-page-section.background-alt+.bccampus-page-section-footer .bccampus-page-section-actions::before {
  background-image: linear-gradient(180deg, rgba(var(--background-color-alt-rgb), 0), var(--background-color-alt));
}

.bccampus-page-section.background-dark+.bccampus-page-section-footer {
  background-color: var(--background-color-dark);
}

.bccampus-page-section.background-dark+.bccampus-page-section-footer .bccampus-page-section-actions::before {
  background-image: linear-gradient(180deg, rgba(var(--background-color-dark-rgb), 0), var(--background-color-dark));
}

.bccampus-page-section.background-dark-alt+.bccampus-page-section-footer {
  background-color: var(--background-color-dark-alt);
}

.bccampus-page-section.background-dark-alt+.bccampus-page-section-footer .bccampus-page-section-actions::before {
  background-image: linear-gradient(180deg, rgba(var(--background-color-dark-alt-rgb), 0), var(--background-color-dark-alt));
}

.bccampus-page-section-container {
  display : flex;
  position: relative;
  height  : 100%;
  width   : var(--page-section-container-width);
  margin  : var(--page-section-container-margin);
}

.bccampus-page-section-image {
  flex-grow  : 0;
  flex-shrink: 0;
  position   : relative;
  height     : 100%;
  text-align : center;
}

.bccampus-page-section-content {
  flex: auto;
}

.bccampus-page-section-container.left {
  flex-direction: var(--page-section-left-content-dir);
}

.bccampus-page-section-container.right {
  flex-direction: var(--page-section-right-content-dir);
}

.bccampus-page-section-image>img {
  width   : var(--page-section-image-width);
  position: var(--page-section-image-position);
  z-index : 1;
}

.bccampus-page-section-hero-actions {
  margin: 1em 0em;
}
