@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

:root {
  --base-font-size        : @font-size-base;
  --title-font-size       : 2.25rem;
  --primary-color         : #4e97ff;
  --primary-color-light   : #62bfd8;
  --primary-color-dark    : #005f7b;
  --primary-color-dark-alt: #005f7b;
  --secondary-color       : #ffd806;
  --secondary-color-light : #bfdfa3;
  --secondary-color-dark  : #ff9037;

  --background-color         : #ffffff;
  --background-color-alt     : #e3edff;
  --background-color-dark    : #005f7b;
  --background-color-dark-alt: #163a50;
  --background-color-light   : #e8eced;

  --background-color-rgb         : 255, 255, 255;
  --background-color-alt-rgb     : 247, 245, 247;
  --background-color-dark-rgb    : 11, 38, 81;
  --background-color-dark-alt-rgb: 8, 30, 64;

  --text-color-light: #ffffff;
  --text-color-dark : #000000;

  --border-radius: 8px;
  --border-color : @border-color-base;
  --border-width : @border-width-base;
  --border-style : @border-width-base @border-style-base @border-color-base;

  --outline-offset: 0.25rem;

  // Link
  --link-text-size      : 1em;
  --link-text-weight    : 400;
  --link-text-decoration: underline;
  --link-border-radius  : 2px;

  --link-text-color       : var(--primary-color-dark);
  --link-text-color-hover : var(--primary-color);
  --link-text-color-active: var(--secondary-color-dark);
  --link-text-color-focus : var(--primary-color-dark);
  --link-outline-focus    : 3px solid #005f7b;

  --link-text-color-in-dark       : var(--text-color-light);
  --link-text-color-hover-in-dark : var(--secondary-color-dark);
  --link-text-color-active-in-dark: var(--primary-color-dark);
  --link-text-color-focus-in-dark : var(--primary-color-light);
  --link-outline-focus-in-dark    : 2px solid var(--secondary-color-light);

  // Button
  --button-text-size    : calc(var(--base-font-size) + 2px);
  --button-border-radius: 0.5rem;
  --button-border-width : 2px;
  --button-text-weight  : 800;
  --button-text-padding : 0.5em 1em;

  --button-text-color             : var(--text-color-light);
  --button-background-color       : #005f7b;
  --button-outline-focus          : 3px solid #005f7b;
  --button-background-color-hover : #e3edff;
  --button-background-color-active: var(--secondary-color-dark);

  --button-text-color-in-dark             : var(--text-color-dark);
  --button-background-color-in-dark       : var(--background-color);
  --button-outline-focus-in-dark          : 3px solid var(--secondary-color-light);
  --button-background-color-hover-in-dark : var(--secondary-color-dark);
  --button-text-color-hover-in-dark       : var(--text-color-light);
  --button-background-color-active-in-dark: var(--primary-color-dark);
  --button-text-color-active-in-dark      : var(--primary-color-light);

  // Card
  --card-border-radius: var(--border-radius);
  --card-shadow       : 0px 0px 8px 2px #dddddd;
  --card-shadow-hover : 0px 0px 8px 2px var(--primary-color);
  --card-shadow-active: 0px 0px 8px 2px var(--secondary-color-dark);
  --card-outline-focus: 3px solid var(--secondary-color-light);

  --card-shadow-in-dark       : 0px 0px 8px 2px #112747;
  --card-shadow-hover-in-dark : 0px 0px 8px 2px var(--primary-color);
  --card-shadow-active-in-dark: 0px 0px 8px 2px var(--secondary-color-dark);
  --card-outline-focus-in-dark: 3px solid var(--secondary-color-light);

  // Misc
  --success-color: @success-color;
  --error-color  : @error-color;

  --app-bar-height                : 65px;
  --content-menu-height           : 65px;
  --app-sidebar-width             : 100px;
  --content-sidebar-width         : 75px;
  --padding                       : 1em;
  --page-section-padding-v        : 2em;
  --page-section-padding-h        : 5em;
  --page-section-left-content-dir : row-reverse;
  --page-section-image-width      : calc(100% - 2em);
  --page-section-image-position   : absolute;
  --page-section-right-content-dir: row;
  --page-section-container-width  : 100%;
  --page-section-container-margin : 0em;

  --home-page-hero-image-display    : block;
  --home-page-section-content-pading: 3em 0em;

  font-size: 100%;
}


.grecaptcha-badge {
  visibility: hidden;
}

body,
.background-default {
  background-color: var(--background-color);
}

.background-alt {
  background-color: var(--background-color-alt);
}

.background-dark {
  background-color: var(--background-color-dark);
}

.background-dark-alt {
  background-color: var(--background-color-dark-alt);
}

.background-transparent {
  background-color: transparent;
}

.background-default.hero {
  background: linear-gradient(180deg, #fdfeff, 55%, #dfeafa);
}

.background-dark.hero {
  background: linear-gradient(180deg, var(--background-color-dark), 55%, var(--secondary-color-dark));
}

.background-dark.hero-alt {
  background: linear-gradient(180deg, var(--background-color-dark), 55%, #28837e);
}

body,
.background-default,
.background-alt,
.background-transparent {
  font-family: 'Open Sans', sans-serif !important;
  font-family: 'Open Sans';

  font-size  : var(--base-font-size);
  font-style : normal;
  font-weight: 400;

  color: var(--text-color-dark) !important;

  overscroll-behavior: contain;


  & ::selection {
    background-color: var(--background-color-dark) !important;
    color           : var(--text-color-light) !important;
  }
}

.background-dark,
.background-dark-alt {
  color: var(--text-color-light) !important;

  & ::selection {
    background-color: var(--text-color-light) !important;
    color           : var(--background-color-dark) !important;
  }
}

.background-dark .bccampus-logo,
.background-dark-alt .bccampus-logo {
  transition: fill 0.3s, stroke 0.3s;
  fill      : var(--text-color-light) !important;
  stroke    : var(--text-color-light) !important;
}

.background-dark .bccampus-logo:hover,
.background-dark-alt .bccampus-logo:hover {
  fill  : var(--primary-color-light) !important;
  stroke: var(--primary-color-light) !important;
}

.page-header .bccampus-img.in-large-page {
  display: block;
}

.page-header .bccampus-img.in-small-page {
  display: none;
}

.page-header .bccampus-item-group {
  justify-content: center;
}

.bccampus-search-filter-tag.focused,
.bccampus-search-filter-tag:focus {
  outline       : var(--button-outline-focus) !important;
  outline-offset: var(--outline-offset) !important;
}

.ant-input {
  padding: 0.5rem !important;
}

.ant-select-selector {
  height : 2.715em !important;
  padding: 0.25em 0.5em !important;
}

.ant-select-selection-search-input {
  height: 2.715em !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0.5em !important;
}

.ant-checkbox-wrapper {
  align-items: flex-end;
}

.ant-checkbox-inner {
  width : 2em;
  height: 2em;
}

.ant-checkbox-inner::after {
  left: 35%
}

#digital-strengths {
  background-image   : url('./assessment/assets/assessment-background.svg');
  background-repeat  : no-repeat;
  background-size    : 50%;
  background-position: right -25% center;
}

@media (max-width: @screen-sm-min) {
  :root {
    --title-font-size: 2em;
  }

  .page-header .bccampus-img.in-large-page {
    display: none;
  }

  .page-header .bccampus-img.in-small-page {
    display   : block;
    width     : 100%;
    max-height: 24em;
  }

  .page-header .bccampus-item-group {
    justify-content: space-between;
    width          : 100%;
  }

  .page-header .bccampus-item-group>* {
    flex      : auto;
    width     : 100%;
    text-align: center;
  }
}

@media (max-width: @screen-md-min) {
  :root {
    --page-section-padding-v: 2em;
    --page-section-padding-h: 1.25em;

    --page-section-image-width   : 65%;
    --page-section-image-position: relative;

    --page-section-left-content-dir : column;
    --page-section-right-content-dir: column;

    --home-page-section-content-pading: 0em 0em;
  }
}

@media (min-width: @screen-md-min) and (max-width: @screen-lg-max) {
  :root {
    --page-section-padding-v: 2em;
    --page-section-padding-h: 2em;
  }
}

@media (min-width: @screen-xxl-min) {
  :root {
    --page-section-padding-v: 2em;
    --page-section-padding-h: 5em;

    --page-section-container-width : 1440px;
    --page-section-container-margin: auto;
  }
}

@media (max-width: @screen-md-max) {
  .bccampus-site-header .large-header {
    display: none;
  }

  .bccampus-site-footer {
    text-align: center;
  }
}

@media (min-width: @screen-lg-min) {
  .bccampus-site-header .small-header {
    display: none;
  }

}

@font-size-base: 1rem;@primary-color: #5e9aff;@border-radius-base: 8px;@link-color: #1f3cb2;@input-placeholder-color: #62bfd8;@border-color-base: #62bfd8;@error-color: #ff9037;