.bccampus-card-title {
  margin-bottom: 0.25em !important;
  width        : 100%;
  font-size    : 1.15em;
  line-height  : 1.25em;
  font-weight  : 600;
}

.bccampus-card-subtitle {
  margin-bottom: 0.25em !important;
  width        : 100%;
  font-size    : 1em;
  line-height  : 1.25em;
}

.bccampus-card-extra {
  width      : 100%;
  font-size  : 1em;
  line-height: 1.25em;
}

.bccampus-card-meta {
  font-size  : 0.95em;
  font-weight: 400;
  line-height: 1.25em;
}


.bccampus-features {
  width: 100%;
}

.bccampus-feature-label {
  color: #166600;
}

.bccampus-feature-tag {
  border       : none;
  border-radius: 1em;
  padding      : 0.25em 1em;
}

.bccampus-feature-tag.style-1 {
  border-color    : #166600;
  background-color: #166600;
  color           : #ffffff;
}

.bccampus-feature-tag.style-2 {
  border-color    : #4e97ff;
  background-color: #4e97ff;
  color           : #ffffff;
}

.bccampus-feature-tag.style-3 {
  border          : 1px solid #005F7B;
  background-color: #ffffff;
  color           : #005F7B;
  border-radius   : 4px;
}

.bccampus-feature-label svg,
.bccampus-feature-tag svg {
  margin-right: 0.25em;
}

#about .bccampus-feature-tag {
  border-radius: 4px;
}
