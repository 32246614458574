.bccampus-search-filter-box {
  padding    : 1em !important;
  line-height: 1em !important;
  height     : 6.5em;
  width      : 100%;
  overflow   : hidden;
  position   : relative;
  text-align : left !important;
}

.bccampus-search-filter-box span {
  display    : inline-flex;
  align-items: flex-start;
  gap        : 1rem;
}

.bccampus-search-filter-box:hover {
  box-shadow: 0px 0px 8px 2px !important;
}

.bccampus-search-filter-box-label {
  position   : relative;
  color      : var(--text-color-dark);
  font-size  : 1em !important;
  line-height: 1em !important;
  font-weight: 600 !important;
  width      : 100%;
  height     : 100%;
}

.bccampus-search-filter-box-icon {
  transition: opacity 0.5s;
  display   : inline-block;
}

.bccampus-search-filter-box-icon svg,
.bccampus-search-filter-box-icon img {
  height: 100%;
}

.bccampus-search-filter-box-icon img {
  object-fit: contain;
}

.bccampus-search-filter-box:hover .bccampus-search-filter-box-icon {
  opacity: 0.6 !important;
}
