.bccampus-search-filter-tag {
  padding       : 0em 0.75em !important;
  line-height   : 1em !important;
  border-radius : 1em !important;
  outline-offset: 0.125em !important;
}

.bccampus-search-filter-tag>svg {
  margin-right: 0.5em;
}

.bccampus-search-filter-tag-count {
  padding-left: 0.5em;
}

.bccampus-search-filter-tag.checkable {
  cursor       : pointer;
  margin-bottom: 0.5em !important;
}

.bccampus-search-filter-tag.checked {
  background-color: var(--primary-color);
  color           : var(--text-color-light);
  border          : none !important;
}

.bccampus-search-filter-tag span {
  line-height: 2em !important;
}

.bccampus-search-filter-tag.checked span {
  color: #ffffff !important;
}
