.bccampus-navigation.horizontal {
  display        : flex;
  gap            : 1.875em;
  justify-content: flex-end;
}

.bccampus-navigation.vertical {
  text-align: center;
}

.bccampus-navigation-item {
  color      : var(--primary-color-dark);
  font-weight: 600;
  border     : var(--link-text-border);
}

.bccampus-navigation-item:focus {
  outline       : var(--link-outline-focus) !important;
  outline-offset: var(--outline-offset);
  border-radius : 2px !important;
}

.bccampus-navigation-item:hover {
  color : var(--text-color-dark);
  border: var(--link-text-border);
}

.bccampus-navigation.horizontal>.bccampus-navigation-item {
  display       : inline-block;
  padding       : calc(var(--padding)/2) 0em;
  line-height   : 1em;
  height        : 2em;
}

.bccampus-navigation.vertical>.bccampus-navigation-item {
  display    : block;
  font-Size  : 0.75em;
  line-height: 2em;
  margin     : 1em 0em;
  padding    : var(--padding)
}

.bccampus-navigation.vertical>.bccampus-navigation-item>.anticon {
  display  : block;
  font-size: 2em;
}

.bccampus-navigation-item.active,
.bccampus-navigation-item.active:focus {
  color : var(--text-color-dark);
  border: var(--link-text-border);
}

.bccampus-navigation.horizontal>.bccampus-navigation-item.active {
  border-bottom: 4px solid var(--text-color-dark);
}

.bccampus-navigation.vertical>.bccampus-navigation-item.active {
  border-right: 4px solid var(--text-color-dark);
}
