.bccampus-list-item {
  padding         : 0em;
  width           : 100% !important;
  height          : 5em;
  background-color: var(--background-color);
  border-radius   : 8px 8px 0px 0px !important;
  border-bottom   : var(--border-width) solid var(--background-color-light) !important;
  box-shadow      : none !important;
}

.bccampus-list-item>span {
  width: 100%;
}

.bccampus-list-item:hover {
  box-shadow: 0px 8px 8px -4px var(--background-color-light) !important;
}

.bccampus-list-item-cover {
  object-fit     : cover;
  object-position: right top;
  width          : 4em;
  height         : 4em;
  border-radius  : 8px !important;
}

.bccampus-list-item-body {
  width      : 20em;
  padding-top: 0.5em;
  height     : 100%;
  position   : relative;
  flex       : 1;
}

.bccampus-list-item-title {
  margin     : 0em !important;
  padding    : 0em;
  width      : 100% !important;
  font-size  : 1.15em;
  line-height: 1.25em;
  font-weight: 600;
}

.bccampus-list-item-subtitle {
  margin-bottom: 0em !important;
  width        : 100% !important;
  line-height  : 1.25em;
}

.bccampus-list-item-extra {
  line-height: 1.25em;
  width      : 100% !important;
}

.bccampus-list-item-meta {
  text-align: right;
}

.bccampus-list-item-meta span {
  color     : var(--text-color-dark);
  word-break: break-all !important;
  word-wrap : break-word !important;
}

.bccampus-list-item-meta span.bccampus-tag {
  display         : inline-block;
  line-height     : 1.25em;
  padding         : 0.35em 0.5em;
  margin-left     : 0.5em;
  margin-bottom   : 0.5em;
  background-color: #28837e;
  border-radius   : 8px !important;
  color           : #ffffff;
}

.bccampus-list-item-meta span.bccampus-tag.style-2 {
  font-size       : 0.875rem;
  line-height     : 1em;
  padding         : 0.25em 0.5em;
  background-color: #1f3cb2;
  color           : #ffffff;
}


.bccampus-list-item-meta span.bccampus-tag>svg {
  margin-right: 0.25em;
}
