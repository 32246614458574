.bccampus-filter-tag-group {
  position        : relative;
  border-radius   : var(--border-radius);
  background-color: var(--background-color);
  display         : inline-block;
  width           : 19em;
  text-align      : left;
}

.bccampus-filter-tag-group-title {
  cursor          : pointer;
  padding         : 0.125em 0.5em;
  padding-right   : 4em;
  border          : 2px solid var(--primary-color) !important;
  border-radius   : var(--border-radius);
  min-height      : 2.5em;
  overflow        : hidden;
  width           : 100%;
  background-color: transparent;
  text-align      : left;
  display         : flex;
  align-items     : center;
  gap             : 0.5em;
}

.bccampus-filter-tag-group-title .bccampus-typography-label,
.bccampus-filter-tag-group-title .bccampus-typography-text {
  color      : var(--primary-color) !important;
  line-height: 1.75em !important;
}

.bccampus-filter-tag-group-body {
  position        : absolute;
  display         : none;
  background-color: var(--background-color);
  width           : 100%;
  z-index         : 10;
  padding         : 1em;
  max-height      : 20em;
  overflow-x      : hidden;
  overflow-y      : auto;
  border          : 2px solid var(--primary-color) !important;
  border-radius   : 0px 0px var(--border-radius) var(--border-radius);
  border-top      : none;
}

.bccampus-filter-tag-group.active>.bccampus-filter-tag-group-title {
  border-radius: var(--border-radius) var(--border-radius) 0px 0px;
  outline      : none;
}

.bccampus-filter-tag-group.active>.bccampus-filter-tag-group-body {
  display     : block;
  border-color: var(--primary-color) !important;
  outline     : none;
  border-top  : none !important;
}
