 @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700;800&display=swap');

 #root {
   width : 100%;
   height: 100%;
 }

 body {
   overscroll-behavior: contain;
 }

 #bccampus-dl-assessment {
   width : 100%;
   height: 100%;

   color: #000000;

   font-family: 'Open Sans', sans-serif !important;
   font-family: 'Open Sans';
   font-style : normal;
   font-weight: 600;

   background-color: #4E97FF;
 }

 #bccampus-dl-assessment>.default,
 #bccampus-dl-assessment>.centered {
   background-image : url('assets/assessment-background.svg');
   background-repeat: no-repeat;
   background-size  : 150%;

   display       : flex;
   flex-direction: column;
   width         : 100%;
   height        : 100%;
 }

 #bccampus-dl-assessment>.default {
   background-position: center 80vh;
 }

 #bccampus-dl-assessment>.centered {
   background-position: center 0em;
 }

 #bccampus-dl-assessment .footer,
 #bccampus-dl-assessment .header {
   padding: 1em 1em;
   flex   : 0 0;
 }

 #bccampus-dl-assessment .content {
   overflow: auto;
   flex    : auto;
 }

 #bccampus-dl-assessment .content .section {
   padding: 1.5em;
 }

 #bccampus-dl-assessment .action {
   padding   : 1.5em 0.5em;
   text-align: center;
 }

 #bccampus-dl-assessment .banners {
   display       : flex;
   flex-direction: column;
   align-items   : center;
   text-align    : center;

   gap    : 1.5em;
   padding: 2em 4em;

   background-color: #00204D;
   color           : #FFFFFF;
 }

 #bccampus-dl-assessment .banners .heading {
   font-size          : 1em;
   text-shadow        : none;
   -webkit-text-stroke: 0px;
 }

 #bccampus-dl-assessment .banners img,
 #bccampus-dl-assessment .banners svg {
   width: 80%;
 }

 #bccampus-dl-assessment .banners svg .text .cls-5,
 #bccampus-dl-assessment .banners svg {
   fill: #ffffff !important;
 }

 #bccampus-dl-assessment>.centered .content .section {
   display        : flex;
   justify-content: center;
   flex-direction : column;
   height         : 90%;
   gap            : 3em;
 }

 #bccampus-dl-assessment .header {
   position       : absolute;
   top            : 0;
   width          : 100%;
   display        : flex;
   gap            : 1rem;
   color          : #ffffff;
   justify-content: space-between;
 }

 #bccampus-dl-assessment .footer {
   display       : flex;
   flex-direction: column;
   gap           : 1rem;
   align-items   : center;

   color           : #000000;
   background-color: rgba(255, 255, 255, 0.5);
   text-align      : center;
 }

 #bccampus-dl-assessment .heading {
   color      : #ffffff;
   text-align : center;
   font-weight: 800;
   font-size  : 1.5em;
   line-height: 1em;
   text-shadow: -0.15rem 0.15rem #000000, 0 0 2px #000000;
 }

 @supports (-webkit-text-stroke: 1px #000000) {
   #bccampus-dl-assessment .heading {
     -webkit-text-stroke: 1px #000000;
   }
 }

 #bccampus-dl-assessment .content>.heading {
   padding: 0em 1.25em;
 }

 #bccampus-dl-assessment .button {
   display      : inline-block;
   color        : #000000;
   background   : #ffd600;
   border       : 2px solid #000000;
   border-radius: 8px;
   position     : relative;
   box-shadow   : -0.4rem 0.15rem 0.25rem -0.35rem #ffd600, -0.35rem 0.35rem 0rem #000000;

   font-weight    : 800;
   text-align     : center;
   text-decoration: none !important;
   white-space    : nowrap;

   margin : 0 auto;
   padding: 0.25em 1em;

   cursor: pointer;
 }

 #bccampus-dl-assessment .button:hover {
   color     : #000000;
   background: rgba(246, 208, 0, 0.5);
 }

 #bccampus-dl-assessment .button.chubby {
   font-size: 2em;
   padding  : 0.25em 0.5em;
 }

 #bccampus-dl-assessment .link {
   color          : #000000;
   font-weight    : 700;
   text-decoration: underline;
 }

 #bccampus-dl-assessment .link:hover {
   color: #000000;
 }

 #bccampus-dl-assessment .category-done,
 #bccampus-dl-assessment .category-link {
   display   : inline-block;
   width     : 100%;
   background: none;
   border    : none;
   padding   : 0em;
   margin    : 0em;
 }

 #bccampus-dl-assessment .category-done>span>.ribbon {
   position: absolute;
   top     : -0.5em;
   right   : calc(50% - 3.5em);
 }

 #bccampus-dl-assessment .category-done>span>.ribbon>img {
   width: 2.5rem;
 }

 #bccampus-dl-assessment .category-done>span>.label {
   font-weight: 800;
 }

 #bccampus-dl-assessment .category-done>span,
 #bccampus-dl-assessment .category-link>span {
   display       : flex;
   flex-direction: column;
   align-items   : center;
   gap           : 0.5em;
   cursor        : pointer;
 }

 #bccampus-dl-assessment .category-done img,
 #bccampus-dl-assessment .category-link>span img {
   object-fit: contain;
   width     : 3.5em;
   filter    : drop-shadow(0em 0.25em 0.25em rgba(0, 0, 0, 0.75));
 }

 #bccampus-dl-assessment .category-link .img-wrapper,
 #bccampus-dl-assessment .category-done .img-wrapper {
   display      : inline-block;
   border-radius: 3em;
   padding      : 0.5em;
 }

 #bccampus-dl-assessment .category-done .img-wrapper {
   background-color: #a4e280;
 }

 #bccampus-dl-assessment .category-link:hover .img-wrapper,
 #bccampus-dl-assessment .category-done:hover .img-wrapper {
   background-color: #ffd600;
   box-shadow      : 0em 0em 0.75em 0.75em #ffd600;
 }

 #bccampus-dl-assessment .category-link>span>.button {
   width  : 100%;
   padding: 0.125em;
 }

 #bccampus-dl-assessment .category-link:hover>span>img {
   filter: drop-shadow(0em 0em 2em rgb(255, 214, 0));
 }

 #bccampus-dl-assessment .category-result {
   display       : flex;
   flex-direction: column;
   align-items   : center;
 }

 #bccampus-dl-assessment .category-result img {
   width : 6em;
   filter: drop-shadow(0em 0.25em 0.25em rgba(0, 0, 0, 0.25));
 }

 #bccampus-dl-assessment .category-result-card {
   text-align: center;
 }


 #bccampus-dl-assessment>.assessment-result {
   background-image: none;
 }

 #bccampus-dl-assessment>.assessment-result .assessment-result-header {
   padding         : 2em 1.5em;
   color           : #000000;
   background-color: #ffd600;

   background-image   : url('assets/assessment-background.svg');
   background-repeat  : no-repeat;
   background-position: center -30vh;
   background-size    : 150%;
 }

 #bccampus-dl-assessment>.assessment-result .assessment-result-header button.text,
 #bccampus-dl-assessment>.assessment-result .assessment-result-header a.default {
   color: #000000 !important;
 }

 #bccampus-dl-assessment>.assessment-result .category-result-card {
   margin-bottom: 2em;
 }

 #bccampus-dl-assessment>.assessment-result .assessment-result-details .heading {
   margin-bottom: 1em;
 }

 #bccampus-dl-assessment .action .title,
 #bccampus-dl-assessment .category-result-card .title {
   margin-bottom: 1em;
   font-size    : 1em;
   font-weight  : 800;
 }

 #bccampus-dl-assessment .category-result .category-result-card .title {
   font-size: 1.25em;
 }

 #bccampus-dl-assessment>.assessment-result .category-result-card img {
   width : 4em;
   filter: drop-shadow(0em 0.25em 0.25em rgba(0, 0, 0, 0.25));
 }

 #bccampus-dl-assessment .category-result .img-wrapper,
 #bccampus-dl-assessment>.assessment-result .img-wrapper {
   display      : inline-block;
   margin       : 1em 0em 1em 0em;
   padding      : 0.5em;
   border-radius: 6em;

   background-color: #a4e280;
 }


 #bccampus-dl-assessment .category-link:hover>span>.button {
   color     : #000000;
   background: rgba(246, 208, 0, 0.5);
 }

 #bccampus-dl-assessment .question .slider:not(.disabled):focus-within,
 #bccampus-dl-assessment .question .choices:not(.readonly):focus-within,
 #bccampus-dl-assessment .button:focus,
 #bccampus-dl-assessment .link:focus,
 #bccampus-dl-assessment a:focus,
 #bccampus-dl-assessment button:focus,
 #bccampus-dl-assessment input:focus {
   outline       : 0.25rem solid #ff9037 !important;
   outline-offset: 0.25rem !important;
   border-radius : 0.5em !important;
 }

 #bccampus-dl-assessment .category-link:focus>span>img {
   filter: drop-shadow(0em 0em 2em #ff9037);
 }

 #bccampus-dl-assessment .content button.text,
 #bccampus-dl-assessment .content a.default {
   color: #ffffff !important;
 }

 #bccampus-dl-assessment .header button.text,
 #bccampus-dl-assessment .header a {
   line-height    : 1em;
   padding        : 0.375em 0.375em;
   color          : #ffffff !important;
   border-radius  : 0.5em !important;
   text-decoration: none !important;
 }

 #bccampus-dl-assessment .footer button.text,
 #bccampus-dl-assessment .footer a {
   color      : #000000 !important;
   font-weight: 700;
 }

 #bccampus-dl-assessment .footer .button {
   width: 18rem;
 }

 #bccampus-dl-assessment .header a {
   background: #4E97FF;
 }

 #bccampus-dl-assessment .question>.text {
   margin    : 2em 0em 1em 0em;
   text-align: center;
 }

 #bccampus-dl-assessment .question>.choices>div {
   display      : flex;
   align-items  : center;
   gap          : 0.875rem;
   padding      : 0.5rem 0.875rem;
   margin       : 0.875rem 0rem;
   background   : #F4EECA;
   border       : 2px solid #000000;
   border-radius: 0.5em;
   box-shadow   : -0.3rem 0.15rem 0.25rem -0.25rem #F4EECA, -0.25rem 0.25rem 0rem #000000;
   cursor       : pointer;
 }

 #bccampus-dl-assessment .question>.choices.readonly>div {
   cursor: default;
 }

 #bccampus-dl-assessment .question>.choices:not(.readonly)>div:focus,
 #bccampus-dl-assessment .question>.choices:not(.readonly)>div:hover {
   background: #ffd600;
 }

 #bccampus-dl-assessment .question>.choices>div>.choice-key {
   font-size  : 1.5em;
   line-height: 1em;
   font-weight: 600;
   text-align : center;

   padding   : 0.375rem 0.5rem;
   min-width : 2.5rem;
   max-width : 2.5rem;
   min-height: 2.5rem;
   max-height: 2.5rem;

   border       : 1px solid #000000;
   box-shadow   : 0rem 0rem 0.25rem #000000;
   color        : #000000;
   background   : #F4EECA;
   border-radius: 2rem;
 }

 #bccampus-dl-assessment .question>.choices.multiple-select>div>.choice-key {
   border-radius: 0.5em;
 }

 #bccampus-dl-assessment .question>.choices>div.selected>.choice-key {
   font-weight: 800;
   background : #000000;

   outline       : 0.5rem solid #F4EECA !important;
   outline-offset: -0.625rem !important;
 }

 #bccampus-dl-assessment .question>.choices>div.correct.selected>.choice-key {
   background: #a4e280;
   outline   : none !important;
 }

 #bccampus-dl-assessment .question>.choices>div.incorrect.selected>.choice-key {
   background: #fd0042;
   outline   : none !important;
 }

 #bccampus-dl-assessment .slider {
   height: 20em;
   width : 15em;
   margin: 0 auto;
 }

 #bccampus-dl-assessment .slider_thumb {
   left  : 0em;
   width : 4em;
   height: 3em;

   background-image   : url('assets/slider_thumb.png');
   background-repeat  : no-repeat;
   background-position: center;
   background-size    : contain;
   cursor             : pointer;
 }

 #bccampus-dl-assessment .slider:not(.disabled) .slider_thumb:hover,
 #bccampus-dl-assessment .slider_thumb.active {
   filter : drop-shadow(0em 0em 1em #ffffff);
   outline: none;
 }

 #bccampus-dl-assessment .slider_track {
   left : 1.25em;
   width: 1.5em;

   background   : #ffd600;
   border       : 0.2em solid #373737;
   box-shadow   : 0em 0.25em 0.25em rgba(0, 0, 0, 0.25);
   border-radius: 5em;
 }

 #bccampus-dl-assessment .slider_mark {
   left  : 1.625em;
   width : 100%;
   height: 2em;
   margin: 0em;

   line-height: 1em;
   font-weight: 700;
   cursor     : pointer;
 }

 #bccampus-dl-assessment .slider.disabled .slider_thumb,
 #bccampus-dl-assessment .slider.disabled .slider_mark {
   cursor: default;
 }

 #bccampus-dl-assessment .slider:not(.disabled) .slider_mark:hover,
 #bccampus-dl-assessment .slider_mark.selected {
   color: #ffffff;
 }

 #bccampus-dl-assessment .slider_mark.correct {
   color      : #a4e280;
   text-shadow: 0em 0em 1em #000000;
 }

 #bccampus-dl-assessment .slider_mark.incorrect.selected {
   color      : #fd0042;
   text-shadow: 0em 0em 0.5em #ffffff;
 }


 #bccampus-dl-assessment .slider:not(.disabled) .slider_mark:hover::before {
   background  : #ffd600;
   border-color: #ffffff;
 }

 #bccampus-dl-assessment .slider_mark::before {
   background   : #ffffff;
   border-radius: 2em;
   border       : 0.15em solid #373737;
   content      : "";
   display      : block;
   width        : 0.75em;
   height       : 0.75em;
   margin-right : 2em;
   margin-top   : 0.125em;
   margin-bottom: 3em;
   float        : left;
 }

 #bccampus-dl-assessment .slider_mark.correct::before {
   background: #a4e280;
 }

 #bccampus-dl-assessment .divider {
   text-align : center;
   font-size  : 1.25em;
   font-weight: 800;
   width      : 100%;
 }

 .divider::before,
 .divider::after {
   content      : "";
   display      : inline-block;
   margin       : 0.25em 0.25em;
   min-width    : 25%;
   border-bottom: 0.25em solid #00204D;
   border-radius: 2em;
 }


 #bccampus-dl-assessment .divider>span {
   padding         : 0.5em;
   font-size       : 1em;
   color           : #FFFFFF;
   background-color: #00204D;
   border-radius   : 2em;
 }

 #bccampus-dl-assessment .paragraph p {
   margin: 0em;
 }

 #bccampus-dl-assessment .paragraph {
   color           : #000000;
   background-color: rgba(255, 255, 255, 0.85);
   border-radius   : 0.25em;
   padding         : 0.5em 1em;
   margin          : 0.5em 0em;
 }

 #bccampus-dl-assessment .paragraph.error {
   color      : #ea0034;
   text-align : center;
   font-weight: 700;
 }

 #bccampus-dl-assessment .paragraph.success {
   color      : #3e7557;
   text-align : center;
   font-weight: 700;
 }

 #bccampus-dl-assessment input {
   border          : 0.25em solid #00204D;
   border-radius   : 0.5em;
   background-color: rgba(255, 255, 255, 0.75);
   font-weight     : 800;
   text-align      : center;
   color           : #00204D;
   padding         : 0.5em;
   width           : 18em;
 }

 #bccampus-dl-assessment input::placeholder {
   color: #373737;
 }

 .bccampus-dl-assessment-modal {
   position: absolute;
   z-index : 100;
   top     : 0;
   left    : 0;

   width : 100%;
   height: 100%;

   pointer-events: none;
 }


 .bccampus-dl-assessment-modal.close {
   display: none;
 }

 .bccampus-dl-assessment-modal.open {
   display: block;
 }

 .bccampus-dl-assessment-modal>.modal-container {
   display       : flex;
   flex-direction: column;
   align-items   : center;

   width : 100%;
   height: 100%;

   pointer-events: none;
 }

 .bccampus-dl-assessment-modal.with-mask>.modal-container {
   background-color: rgba(25, 25, 25, 0.75);
 }

 .bccampus-dl-assessment-modal.with-mask,
 .bccampus-dl-assessment-modal.close-onclick-mask>.modal-container {
   pointer-events: auto;
 }

 .bccampus-dl-assessment-modal>.modal-container .modal-content {
   position: relative;

   line-height   : 2em;
   pointer-events: auto;

   padding         : 1em 4em;
   border-top      : 0.25em solid #00204D;
   border-bottom   : 0.25em solid #00204D;
   background-color: #4E97FF;

   display       : flex;
   flex-direction: column;
   gap           : 1em;

   align-items: center;
   text-align : center;

   min-width: 100%;
   max-width: 100%;

   max-height: 80vh;
 }

 .bccampus-dl-assessment-modal.dismissable.message>.modal-container .modal-content {
   padding-right: 4em;
 }

 .bccampus-dl-assessment-modal.pos-default>.modal-container,
 .bccampus-dl-assessment-modal.pos-bottom>.modal-container {
   justify-content: flex-end;
 }

 .bccampus-dl-assessment-modal.pos-top>.modal-container {
   justify-content: flex-start;
 }

 .bccampus-dl-assessment-modal.pos-center>.modal-container {
   justify-content: center;
 }


 .bccampus-dl-assessment-modal>.modal-container .modal-content .modal-actions {
   display        : flex;
   justify-content: center;
   gap            : 2em;

   width: 100%;
 }

 .bccampus-dl-assessment-modal>.modal-container .modal-content .modal-actions .button {
   min-width: 5em;
   width    : auto !important;
 }

 .bccampus-dl-assessment-modal>.modal-container .modal-content .modal-actions .button.positive {

   background-color: #bfdfa3 !important;
 }

 .bccampus-dl-assessment-modal>.modal-container .modal-content .modal-actions .button.negative {

   background-color: #ff9037 !important;
 }

 .bccampus-dl-assessment-modal>.modal-container .modal-content .modal-tools {
   position: absolute;
   top     : 0;
   right   : 0;
   padding : 1em;
 }

 .bccampus-dl-assessment-modal .modal-icon {
   position: absolute;
   top     : 0;
   left    : 0;

   margin : 0.875em;
   padding: 0.125em;

   line-height  : 1em;
   border-radius: 2em;

   background-color: #000000;
 }

 .bccampus-dl-assessment-modal.info .modal-icon {
   color: #4472e2;
 }

 .bccampus-dl-assessment-modal.success .modal-icon {
   color: #9de85a;
 }

 .bccampus-dl-assessment-modal.warn .modal-icon {
   color: #ff943c;
 }

 .bccampus-dl-assessment-modal.error .modal-icon {
   color: #ff4d4d;
 }

 .bccampus-dl-assessment-modal>.modal-container .modal-content .modal-tools .button {
   width  : auto !important;
   padding: 0.25em 0.5em !important;

   line-height: 1em;

   box-shadow: none !important;
 }

 .bccampus-dl-assessment-modal>.modal-container .modal-content .modal-tools .button .bccampus-button-icon {
   margin: 0;
 }

 #bccampus-dl-assessment .hero-image {
   width: 100%;
 }

 @media (min-width:768px) {
   #root {
     font-size: 125%;
   }

   #bccampus-dl-assessment {
     background-size: cover;
   }

   #bccampus-dl-assessment>.centered {
     background-position: center;
     background-size    : cover;
   }

   #bccampus-dl-assessment .content .section {
     padding: 1.5em calc((100vw - 688px) / 2) 1em calc((100vw - 688px) / 2) !important;
   }

   .bccampus-dl-assessment-modal>.modal-container .modal-content {
     border: 0.25em solid #00204D;

     min-width: 20em;
     max-width: 40em;
   }

   .bccampus-dl-assessment-modal.pos-default>.modal-container {
     justify-content: center;
   }

   .bccampus-dl-assessment-modal.message.pos-default>.modal-container {
     justify-content: flex-start;
   }


   .bccampus-dl-assessment-modal.pos-default>.modal-container .modal-content,
   .bccampus-dl-assessment-modal.pos-center>.modal-container .modal-content {
     border-radius: 1em;
   }

   .bccampus-dl-assessment-modal.message.pos-default>.modal-container .modal-content,
   .bccampus-dl-assessment-modal.pos-top>.modal-container .modal-content {
     margin-top: 1em;
   }

   .bccampus-dl-assessment-modal.message.pos-default>.modal-container .modal-content,
   .bccampus-dl-assessment-modal.message.pos-top>.modal-container .modal-content,
   .bccampus-dl-assessment-modal.message.pos-bottom>.modal-container .modal-content {
     align-items  : flex-start;
     text-align   : left;
     border-radius: 0;
   }

   #bccampus-dl-assessment .question>.choices>div>.choice-key {
     font-size: 1.25em;
   }
 }


 @media (min-width:1024px) {
   #bccampus-dl-assessment .content .section {
     padding: 1.5em calc((100vw - 960px) / 2) 1em calc((100vw - 960px) / 2) !important;
   }
 }

 @media (min-width:768px) and (min-height:768px) {
   #bccampus-dl-assessment .content .section:first-child {
     padding-top: 12.5vh !important;
   }

   #bccampus-dl-assessment .hero-image {
     width: 100%;
   }
 }

 @media (min-width:768px) and (max-height:1023px) {
   #bccampus-dl-assessment .hero-image {
     width: 37.5%;
   }
 }

 @media print {
   #bccampus-dl-assessment {
     background: none;
     font-size : 10pt;
   }

   #bccampus-dl-assessment .content {
     overflow: inherit;
   }

   #bccampus-dl-assessment .assessment-result-header input,
   #bccampus-dl-assessment .assessment-result-header button,
   #bccampus-dl-assessment .assessment-result-header a,
   #bccampus-dl-assessment .assessment-result-header .actions,
   #bccampus-dl-assessment .footer {
     display: none !important;
   }

   #bccampus-dl-assessment .assessment-result-header img {
     width: 25% !important;
   }

   #bccampus-dl-assessment .heading {
     color              : #000000;
     text-shadow        : none;
     -webkit-text-stroke: 0px;

     page-break-after: avoid;
   }

   #bccampus-dl-assessment .link,
   #bccampus-dl-assessment .button,
   #bccampus-dl-assessment .category-result-card {
     page-break-inside: avoid;
   }

   #bccampus-dl-assessment .category-result-card {
     text-align: left;
   }

   .category-result-card .title {
     position: absolute;
   }

   .category-result-card .text {
     padding-top: 2.5em;
   }

   .category-result-card .text::first-letter {
     font-weight: 700;
     font-size  : 16pt;
   }

   #bccampus-dl-assessment .category-result-card .img-wrapper {
     float : left;
     margin: 3em 0.5em 0em 0em;
   }

   #bccampus-dl-assessment .banners svg {
     fill: #000000 !important;
   }

   .ant-col-lg-8 {
     display  : block !important;
     flex     : 0 0 33.33333333% !important;
     max-width: 33.33333333% !important;
   }
 }
