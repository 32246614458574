.bccampus-textbook-info-card {
  padding         : 0em;
  width           : 100%;
  height          : 15em;
  background-color: var(--background-color);
  border-radius   : 8px !important;
}

.bccampus-textbook-info-card:hover {
  box-shadow: 0px 0px 8px 2px #28837e !important;
}

.bccampus-textbook-info-card-cover-wrap {
  display      : inline-block;
  position     : relative;
  width        : 9em;
  height       : 100%;
  border-radius: 8px !important;
}

.bccampus-textbook-info-card-cover {
  float          : left;
  object-fit     : cover;
  object-position: right top;
  width          : 9em;
  height         : 100%;
  border-radius  : 8px 0px 0px 8px !important;
}

.bccampus-textbook-info-card-body {
  width        : 100% !important;
  height       : 100%;
  position     : relative;
  padding      : 0.5em 1em;
  border-radius: 0px 8px 8px 0px !important;
}

.bccampus-textbook-info-card-meta {
  position     : absolute;
  bottom       : 0.5em;
  width        : 100%;
  padding      : 0em 1em;
  border-radius: 0px 0px 8px 8px !important;
}

.bccampus-textbook-info-card-tag {
  padding         : 0.5em 1em;
  background-color: #28837e;
  border-radius   : 0px 8px 0px 0px !important;
  color           : #ffffff;
}

.bccampus-textbook-features {
  width: 100%;
}

.bccampus-textbook-feature-label {
  color: #28837e;
}

.bccampus-textbook-feature-tag {
  border          : none;
  border-radius   : 1em;
  border-color    : #28837e;
  background-color: #28837e;
  color           : #ffffff;
  padding         : 0.25em 1em;
}

.bccampus-textbook-feature-label svg,
.bccampus-textbook-feature-tag svg {
  margin-right: 0.25em;
}

#about .bccampus-textbook-feature-tag {
  border-radius: 4px;
}

.bccampus-textbook-info-card-title {
  margin-bottom: 0.25em !important;
  width        : 100%;
  font-size    : 1.15em;
  font-weight  : 600;
}
